import moment from 'moment/moment'
import pt from '../libs/i18n/locales/pt.json'

export const optionsFromObject = o => Object.entries(o).map(([value, text]) => ({ value, text }))
export const withEmptyOptionFirst = o => [
  { value: '', text: '-- Selecione --' },
  ...o,
]

export const userDemographicsTalkedWith = [
  { value: 'responsible', text: 'Responsável pelo cadastro' },
  { value: 'other', text: 'Outro' },
]

export const companyDemographicObjectives = [
  { value: 'start_my_team', text: 'Iniciar a minha Equipe' },
  { value: 'expand_team', text: 'Ampliar o número de representantes da minha Equipe' },
  { value: 'other', text: 'Outro' },
]

export const representativeDemographicConstitutedCompany = [
  { value: '1', text: 'Sim' },
  { value: '0', text: 'Não' },
]

export const representativeDemographicCore = [
  { value: '1', text: 'Sim' },
  { value: '0', text: 'Não' },
]

export const representativeDemographicPortfolio = [
  { value: '1', text: 'Sim' },
  { value: '0', text: 'Não' },
]

export const representativeDemographicObjectives = [
  { value: 'start_representation', text: 'Começar a trabalhar com representação' },
  { value: 'expand_portfolio', text: 'Ampliar o número de representadas na minha carteira' },
  { value: 'other', text: 'Outro' },
]

export const representativeDemographicQuantityRepresented = [
  { value: '1', text: '1' },
  { value: 'between_2_3', text: 'de 2 a 3' },
  { value: 'between_4_5', text: 'de 4 a 5' },
  { value: 'more_than_5', text: 'mais de 5' },
]

export const representativeDemographicExperienceWithRepresentation = [
  { value: 'without_experience', text: 'Sem experiência' },
  { value: 'between_1_5', text: 'Sim, até 5 anos' },
  { value: 'between_6_10', text: 'Sim, entre 6 e 10 anos' },
  { value: 'between_11_20', text: 'Sim, entre 11 e 20 anos' },
  { value: 'more_than_20', text: 'Sim, mais de 20 anos' },
]

export const userDemographicCompanySize = [
  { value: 'between_1_10', text: 'Até 10 funcionários' },
  { value: 'between_11_50', text: 'Entre 11 e 50 funcionários' },
  { value: 'between_51_100', text: 'Entre 51 e 100 funcionários' },
  { value: 'more_than_100', text: 'Mais de 100 funcionários' },
]

export const userDemographicExperienceWithRepresentatives = [
  { value: 'without_experience', text: 'Não temos experiência' },
  { value: 'between_1_5', text: 'Sim, temos até 5 Representantes' },
  { value: 'between_6_10', text: 'Sim, entre 6 e 10 Representantes' },
  { value: 'between_11_20', text: 'Sim, entre 11 e 20 Representantes' },
  { value: 'more_than_20', text: 'Sim, mais de 20 Representantes' },
]

export const userDemographicCommercialTeamSize = [
  { value: 'no', text: 'Nenhum' },
  { value: 'between_1_3', text: 'De 1 a 3' },
  { value: 'between_4_5', text: 'De 4 a 5' },
  { value: 'between_6_10', text: 'De 6 a 10' },
  { value: 'between_11_20', text: 'De 11 a 20' },
  { value: 'more_than_20', text: 'Mais de 20' },
]

export const userDemographicCrm = [
  { value: '1', text: 'Sim' },
  { value: '0', text: 'Não' },
]

export const userDemographicDigitalMaturity = [
  { value: 'low', text: '1 (usuário com baixo uso de computador/sistemas)' },
  { value: 'intermediary', text: '2 (usuário com uso intermediário de computador/sistemas)' },
  { value: 'high', text: '3 (usuário com alto uso de computador/sistemas)' },
]

export const userDemographicAdherenceToTheProposal = [
  { value: 'low', text: '1 (pouco aderente - expectativas exageradas e pouco disposto a fazer o trabalho necessário)' },
  { value: 'intermediary', text: '2 (intermediário - expectativas exageradas mas disposto a fazer o trabalho necessário)' },
  { value: 'high', text: '3 (aderente - expectativas realistas e disposto a fazer o trabalho necessário)' },
]

export const userDemographicGrowthReplacement = [
  { value: 'expansion', text: 'Expansão' },
  { value: 'replacement', text: 'Substituição' },
]

export const userDemographicPlanning = [
  { value: 'own_planning', text: 'Planejaento próprio' },
  { value: 'need_help', text: 'Precisam de ajuda' },
]

export const userDemographicIsUrgent = [
  { value: '1', text: 'Sim' },
  { value: '0', text: 'Não' },
]

//
// role success
//
export const roleSuccessAnnotationType = [
  { value: 'first_contact', text: 'Primeiro Contato' },
  { value: 'follow_up', text: 'Acompanhamento' },
  { value: 'ticket', text: 'Chamado' },
]

export const roleSuccessAnnotationAssignTo = [
  { value: 'empty', text: 'Não atribuir' },
  { value: 'sales', text: 'Vendas' },
  { value: 'relationship', text: 'Relacionamento' },
]

export const roleSuccessSubscriberOrigins = [
  {
    value: 'onboarding',
    text: 'Onboarding',
    icon: 'CompassIcon',
    variant: 'light-info',
  },
  {
    value: 'new_payment',
    text: 'Novo Pagamento',
    icon: 'DollarSignIcon',
    variant: 'light-success',
  },
  {
    value: 'health_score',
    text: 'Health Score',
    icon: 'PlusSquareIcon',
    variant: 'light-danger',
  },
]

//
// role relationship
//
export const roleRelationshipAnnotationType = [
  { value: 'onboarding', text: 'Onboarding' },
  { value: 'attendance', text: 'Atendimento' },
]

export const roleRelationshipAnnotationAssignTo = [
  { value: 'empty', text: 'Não atribuir' },
  { value: 'sales', text: 'Vendas' },
  { value: 'success', text: 'Sucesso do Usuário' },
]

//
// role sales
//
export const roleSalesContactOptions = [
  { text: 'Sem interesse', value: 'no' },
  { text: 'Link de pagamento enviado', value: 'yes' },
  { text: 'Agendar contato', value: 'schedule' },
  { text: 'Em negociação', value: 'negotiation' },
]

export const roleSalesPipelines = [
  { text: 'Novo Lead', value: 'new_lead' },
  { text: 'Qualificação', value: 'qualification' },
  { text: 'Proposta', value: 'proposal' },
  { text: 'Link Enviado', value: 'negotiation' },
]

//
// role sales - sdr
//
export const roleSalesSdrContactOptions = [
  { text: 'Lead qualificado  (enviar para vendas)', value: 'interest' },
  { text: 'Sem interesse (descartar)', value: 'no_interest' },
]

export const roleSalesSdrQualification = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
]

export const roleSalesSdrLeadInterest = [
  { text: 'Sim', value: '1' },
  { text: 'Não', value: '0' },
]

export const salesLeadOriginOptions = [{
  text: 'Atendimento',
  value: 'attendance',
  icon: 'MessageSquareIcon',
  variant: 'light-success',
  assignable: true,
}, {
  text: 'Duplo',
  value: 'double',
  icon: 'CopyIcon',
  variant: 'light-danger',
}, {
  text: 'Promoção',
  value: 'discount',
  icon: 'GiftIcon',
  variant: 'light-warning',
  assignable: true,
}, {
  text: 'Onboarding',
  value: 'onboarding',
  icon: 'CompassIcon',
  variant: 'light-info',
}, {
  text: 'Renovação',
  value: 'renew',
  icon: 'DeleteIcon',
  variant: 'light-primary',
}, {
  text: 'Gatilho do chat',
  value: 'trigger',
  icon: 'ZapIcon',
  variant: 'light-secondary',
  assignable: true,
}, {
  text: 'Gatilho alteração cadastral',
  value: 'trigger_change_registration',
  icon: 'ZapIcon',
  variant: 'light-secondary',
  assignable: true,
}, {
  text: 'Gatilho (mensagem)',
  value: 'trigger_message',
  icon: 'MessageCircleIcon',
  variant: 'light-secondary',
  assignable: true,
}, {
  text: 'Gatilho (carrinho)',
  value: 'trigger_cart',
  icon: 'ShoppingCartIcon',
  variant: 'light-secondary',
  assignable: true,
}, {
  text: 'Gatilho (telefone)',
  value: 'trigger_phone',
  icon: 'PhoneIcon',
  variant: 'light-secondary',
  assignable: true,
}, {
  text: 'Gatilho (planos)',
  value: 'trigger_plans',
  icon: 'DollarSignIcon',
  variant: 'light-secondary',
  assignable: true,
}, {
  text: 'Lead SDR',
  value: 'lead_sdr',
  icon: 'DollarSignIcon',
  variant: 'light-secondary',
  assignable: true,
}]

//
// geral
//
export const emailSender = [
  {
    value: 'smtp01',
    text: 'Amazon',
  },
  {
    value: 'mandrill',
    text: 'Mandrill',
  },
]

export const managersOptions = (JSON.parse(localStorage.getItem('managers')) || [])
  .sort((a, b) => (moment(b.deleted_at).isBefore(moment(a.deleted_at || undefined)) ? -1 : 1))
  .reduce((groups, manager) => {
    if (manager.id === 1) {
      return groups
    }

    const type = manager.permission_type === 'root' ? 'management_processes' : manager.permission_type
    const managerLabel = manager.deleted_at ? 'Removidos' : pt.roles[type]

    if (!groups.some(f => f.label === managerLabel)) {
      groups.push({
        label: managerLabel,
        options: [{
          value: manager.permission_type,
          text: `Equipe ${managerLabel}`,
        }],
      })
    }

    groups.find(f => f.label === managerLabel).options
      .push({
        value: manager.id,
        text: manager.name,
      })

    return groups
  }, [])

export const annotationBias = [
  { value: 'negative', text: 'Negativo' },
  { value: 'neutral', text: 'Neutro' },
  { value: 'positive', text: 'Positivo' },
]

export const annotationChannels = [
  { value: 'chat', text: 'Chat' },
  { value: 'phone', text: 'Telefone' },
  { value: 'whats', text: 'Whats' },
  { value: 'email', text: 'E-mail' },
]

export const consultantAreas = [
  { value: 'sales', text: 'Vendas' },
  { value: 'success', text: 'Sucesso do Usuário' },
  { value: 'relationship', text: 'Relacionamento' },
  { value: 'service_qualification', text: 'Atendimento e Qualificação' },
  { value: 'root', text: 'Administração' },
  { value: 'manager', text: 'Gerência' },
]

export const userRoles = [
  { value: 'sales', text: 'Vendas' },
  { value: 'sales_sdr', text: 'Vendas - SDR' },
  { value: 'success', text: 'Sucesso do Usuário' },
  { value: 'mentoring', text: 'Mentoria' },
  { value: 'relationship', text: 'Relacionamento' },
  { value: 'service_qualification', text: 'Atendimento e Qualificação' },
  { value: 'management_processes', text: 'Processos Gerenciais' },
]

export const userTypes = [
  { value: 'representative', text: 'Representante' },
  { value: 'company', text: 'Empresa' },
]

export const userTest = [
  { value: '0', text: 'Não' },
  { value: '1', text: 'Sim' },
]

export const delayEmails = [
  { value: 0, text: 'No momento que acontecer' },
  { value: 1, text: 'Receber resumo uma vez por dia' },
  { value: 7, text: 'Receber resumo uma vez por semana' },
]

export const userStatus = [
  { value: 'active', text: 'Ativo' },
  { value: 'basic', text: 'Básico' },
  { value: 'filter', text: 'Filtragem' },
  { value: 'review', text: 'Revisão' },
  { value: 'blocked', text: 'Bloqueado' },
  { value: 'inactive', text: 'Inativo' },
  { value: 'canceled', text: 'Cancelado' },
  { value: 'lead', text: 'Lead' },
]

export const userBlockReasons = [
  { value: 'double', text: 'Duplo' },
  { value: 'not_served', text: 'Segmento não atendido pelo site' },
  { value: 'personal_email', text: 'Sem email corporativo' },
  { value: 'missing_info', text: 'Falta de informações' },
  { value: 'user_request', text: 'Desativou sua conta pelo front' },
  { value: 'other', text: 'Outro' },
]

export const userRepresentationExperience = [
  { value: 'Até 05 anos de experiência com Representação', text: 'Até 05 anos de experiência com Representação' },
  { value: 'Entre 05 e 10 anos de experiência com Representação', text: 'Entre 05 e 10 anos de experiência com Representação' },
  { value: 'Entre 10 e 20 anos de experiência com Representação', text: 'Entre 10 e 20 anos de experiência com Representação' },
  { value: 'Mais de 20 anos de experiência com Representação', text: 'Mais de 20 anos de experiência com Representação' },
]

export const userStates = [
  { value: 'AC', text: 'Acre' },
  { value: 'AL', text: 'Alagoas' },
  { value: 'AP', text: 'Amapá' },
  { value: 'AM', text: 'Amazonas' },
  { value: 'BA', text: 'Bahia' },
  { value: 'CE', text: 'Ceará' },
  { value: 'DF', text: 'Distrito Federal' },
  { value: 'ES', text: 'Espirito Santo' },
  { value: 'GO', text: 'Goiás' },
  { value: 'MA', text: 'Maranhão' },
  { value: 'MS', text: 'Mato Grosso do Sul' },
  { value: 'MT', text: 'Mato Grosso' },
  { value: 'MG', text: 'Minas Gerais' },
  { value: 'PA', text: 'Pará' },
  { value: 'PB', text: 'Paraíba' },
  { value: 'PR', text: 'Paraná' },
  { value: 'PE', text: 'Pernambuco' },
  { value: 'PI', text: 'Piauí' },
  { value: 'RJ', text: 'Rio de Janeiro' },
  { value: 'RN', text: 'Rio Grande do Norte' },
  { value: 'RS', text: 'Rio Grande do Sul' },
  { value: 'RO', text: 'Rondônia' },
  { value: 'RR', text: 'Roraima' },
  { value: 'SC', text: 'Santa Catarina' },
  { value: 'SP', text: 'São Paulo' },
  { value: 'SE', text: 'Sergipe' },
  { value: 'TO', text: 'Tocantins' },
]

export const months = new Array(12).fill(0).map((_, index) => {
  const date = new Date()
  date.setDate(1)
  date.setMonth(index)

  let month = date.toLocaleString('pt-BR', { month: 'long' })
  month = month.charAt(0).toUpperCase() + month.slice(1)

  return {
    value: date.getMonth() + 1,
    text: month,
  }
})

//
// goal management
//
const firstYearToShowInGoalManagementFilter = new Date().getFullYear() + 1
export const goalManagementYearsFilter = new Array(firstYearToShowInGoalManagementFilter - 2020)
  .fill(0)
  .map((_, index) => ({
    value: firstYearToShowInGoalManagementFilter - index,
    text: firstYearToShowInGoalManagementFilter - index,
  }))

export const goalRoles = [
  ...Object.keys(pt.roles).map(role => ({ value: role, text: pt.roles[role] })),
].sort((a, b) => (a.text < b.text ? -1 : 1))

export const goalIntervals = [
  ...Object.keys(pt.goals.intervals).map(interval => ({ value: interval, text: pt.goals.intervals[interval] })),
].sort((a, b) => (a.text < b.text ? -1 : 1))
//

export default { }

//
// internal search
//
export const orderByOptions = [
  {
    value: 'relevance DESC',
    text: 'Relevância',
  },
  {
    value: 'activity_at DESC',
    text: 'Atividade',
  },
  {
    value: 'ranking ASC',
    text: 'Ranking',
  },
  {
    value: 'name ASC',
    text: 'Ordem Alfabética A-Z',
  },
  {
    value: 'name DESC',
    text: 'Ordem Alfabética Z-A',
  },
  {
    value: 'login_at DESC',
    text: 'Login',
  },
]

export const diferentialOptions = [
  { value: 'has_representation_experience', text: 'Experiência com Representação' },
  { value: 'has_company_constituted', text: 'Empresa Constituída' },
  { value: 'has_board_registration_core', text: 'Registro no Conselho (CORE)' },
  { value: 'has_a_customer_portfolio', text: 'Carteira de Clientes já formada' },
  { value: 'has_own_vehicle', text: 'Veículo próprio' },
]

export const optionalOptions = [
  { value: 'only_users_i_have_not_connected', text: 'Usuários não conectados' },
  { value: 'has_site_or_social_media', text: 'Usuários com site/redes sociais' },
  { value: 'only_companies_from_same_state', text: 'Mostrar somente empresas do estado' },
  { value: 'has_not_been_saved_in_internal_search', text: 'Usuários que não foram salvos em outra busca' },
]

export const knownDomainsOptions = [
  { value: 'uol.com', text: 'uol' },
  { value: 'terra.com', text: 'terra' },
  { value: 'gmail.com', text: 'gmail' },
  { value: 'outlook.com', text: 'outlook' },
  { value: 'ymail.com', text: 'ymail' },
  { value: 'hotmail.com', text: 'hotmail' },
  { value: 'bol.com', text: 'bol' },
  { value: 'pop.com', text: 'pop' },
  { value: 'yahoo.com', text: 'yahoo' },
  { value: 'via-rs.com', text: 'via-rs' },
  { value: 'portoweb.com', text: 'portoweb' },
  { value: 'zaz.com', text: 'zaz' },
  { value: 'ibest.com', text: 'ibest' },
  { value: 'ig.com', text: 'ig' },
  { value: 'italnet.com', text: 'italnet' },
  { value: 'sercomtel.com', text: 'sercomtel' },
  { value: 'superig.com', text: 'superig' },
  { value: 'correios.com', text: 'correios' },
  { value: 'click21.com', text: 'click21' },
  { value: 'r7.com', text: 'r7' },
  { value: 'globo.com', text: 'globo' },
  { value: 'msn.com', text: 'msn' },
]

// DNS Status indexed according to their response codes https://www.iana.org/assignments/dns-parameters/dns-parameters.xhtml#dns-parameters-6
export const dnsStatuses = [
  { value: 'NoError', text: 'Sucesso' },
  { value: 'FormErr', text: 'Formato incorreto' },
  { value: 'ServFail', text: 'Falha no servidor' },
  { value: 'NXDomain', text: 'Domínio não existe' },
  { value: 'NotImp', text: 'Não implementado' },
  { value: 'Refused', text: 'Consulta recusada' },
  { value: 'YXDomain', text: 'Nome não deveria existir' },
  { value: 'YXRRSet', text: 'Conjunto de entradas não deveria existir' },
  { value: 'NXRRSet', text: 'Conjunto de entradas deveria existir' },
  { value: 'NotAuth', text: 'Não autorizado' },
  { value: 'NotZone', text: 'Nome não está na zona' },
]

export const activationFlowJourneyTypesOptions = [
  { value: 'registration_filtered', text: 'Cadastro filtrado' },
  { value: 'registration_reviewed', text: 'Cadastro revisado' },
  { value: 'registration_approved', text: 'Cadastro aprovado' },
  { value: 'registration_active_reviewed', text: 'Cadastro ativo revisado' },
  { value: 'user_status_blocked', text: 'Cadastro bloqueado' },
]

export const issueStatusOptions = optionsFromObject(pt.issue.status)
export const issueTagOptions = optionsFromObject(pt.issue.tag)
export const disabledEmailReasonOptions = optionsFromObject(pt.disabledEmail.reason)
export const massEmailStatusOptions = optionsFromObject(pt.mass_email.status)
export const massEmailDiscountTypeOptions = optionsFromObject(pt.mass_email.discount_type)
export const plannerActionDifficultiesOptions = optionsFromObject(pt.planner.content.difficulties)
export const plannerContentTemplateOptions = optionsFromObject(pt.planner.content.templates)
export const plannerContentButtonTargetOptions = optionsFromObject(pt.planner.content.button.targets)
export const plannerContentButtonTypeOptions = optionsFromObject(pt.planner.content.button.types)
