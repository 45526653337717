import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import axios from 'axios'
// import Swal from 'sweetalert2'
import LocalStorageService from '@/services/localStorageService'
import moment from 'moment'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    if (this.getToken() == null || this.getToken() === 'undefined') {
      // this.removeLogin()
    }

    // Debug Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-param-reassign
          config.headers['X-Requested-With'] = 'XMLHttpRequest'
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // parâmetros do analisador de performance que chegam na URL do iframe
    // e devem ser adicionados em todas as requisições de dentro do iframe para que sejam rastreáveis
    this.axiosIns.interceptors.request.use(
      config => {
        /* eslint-disable no-param-reassign */
        if (config.data && config.data.url === `${process.env.VUE_APP_API}/performance-analysis`) {
          return config
        }

        // Can't use '@/router' due to circular dependency
        const urlParams = new URLSearchParams(document.location.search)

        if (urlParams.get('pa_timestamp')) {
          config.params = {
            ...config.params || {},
          }
          config.hafidme = {
            ...config.hafidme || {},
          }

          const timestamp = urlParams.get('pa_timestamp')
          if (timestamp) {
            config.params.pa_timestamp = timestamp
          }

          const name = urlParams.get('pa_name')
          if (name) {
            config.params.pa_name = name
          }

          if (name || timestamp) {
            config.hafidme.begin_at = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
          }
        }

        return config
        /* eslint-enable no-param-reassign */
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(response => {
      const { config, headers } = response
      const backendRequestId = parseInt(headers['hafidme-pa-backend-request-id'], 10)
      if (Number.isInteger(backendRequestId)) {
        const {
          hafidme: {
            begin_at,
          },
          params: {
            pa_timestamp,
            pa_name,
          },
          url,
          data,
        } = config

        window.top.postMessage({
          begin_at,
          end_at: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
          url,
          pa_timestamp,
          pa_name,
          extra: {
            input: data || {},
            backendRequestId,
          },
        }, document.location.origin)
      }

      return response
    })

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        // const { response } = error
        const originalRequest = config

        if (response && response.status === 401) {
          if (response.data.message === 'Faça login novamente!') {
            this.removeLogin()
            document.location.href = '/'
          }
          // Swal.fire({
          //   title: 'Erro de autenticação!',
          //   text: 'Você foi desconectado, por favor, faça o login novamente.',
          //   icon: 'warning',
          //   showCancelButton: true,
          //   customClass: {
          //     confirmButton: 'btn btn-primary',
          //     cancelButton: 'btn btn-outline-danger ml-1',
          //   },
          //   confirmButtonText: 'Relogar (nova aba)',
          //   cancelButtonText: 'Ignorar',
          //   buttonsStyling: false,
          // }).then(result => {
          //   if (result.value) {
          //     this.removeLogin()
          //
          //     window.open('/')
          //   }
          // })
          // }

          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              // Update accessToken in localStorage
              this.setToken(r.data.data.token)
              this.setRefreshToken(r.data.data.token)

              this.onAccessTokenFetched(r.data.accessToken)
            }).finally(() => {
              this.isAlreadyFetchingAccessToken = false
            })
          }

          setTimeout(() => { this.onAccessTokenFetched(this.getToken()) }, 3000)
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  getFullUrl(initUrl, args = null) {
    let newUrl = (initUrl.indexOf('http') > -1) ? initUrl : this.jwtConfig.api + initUrl

    if (args !== null && Object.values(args).length > 0) {
      let add = initUrl.indexOf('?') > -1 ? '&' : '?'
      Object.entries(args).forEach(a => {
        if (Array.isArray(a[1])) {
          a[1].forEach(aList => {
            newUrl += `${add}${a[0]}[]=${aList}`
            add = '&'
          })
        } else {
          newUrl += `${add}${a[0]}=${a[1]}`
        }
        add = '&'
      })
    }

    return newUrl
  }

  // eslint-disable-next-line no-unused-vars
  delete(url, args = null) {
    const newUrl = this.getFullUrl(url, args)
    return this.axiosIns.delete(newUrl)
  }

  // eslint-disable-next-line no-unused-vars
  patch(url, args = null, data = null) {
    const headers = { 'Content-Type': 'application/json' }
    const newUrl = this.getFullUrl(url, args)
    return this.axiosIns.patch(newUrl, { headers })
  }

  // eslint-disable-next-line no-unused-vars
  post(url, args = null, data = null) {
    const newUrl = this.getFullUrl(url, args)
    return this.axiosIns.post(newUrl, data)
  }

  // eslint-disable-next-line no-unused-vars
  put(url, args = null, data = null) {
    const newUrl = this.getFullUrl(url, args)
    return this.axiosIns.put(newUrl, data)
  }

  // eslint-disable-next-line no-unused-vars
  download(url, args = null) {
    const newUrl = this.getFullUrl(url, args)
    return this.axiosIns.get(newUrl, { responseType: 'blob' })
  }

  // eslint-disable-next-line no-unused-vars
  get(url, args = null) {
    const newUrl = this.getFullUrl(url, args)
    return this.axiosIns.get(newUrl)
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  getExternal(url) {
    return axios.get(url)
  }

  removeLogin() {
    LocalStorageService.remove(this.jwtConfig.storageTokenKeyName)
    LocalStorageService.remove(this.jwtConfig.storageRefreshTokenKeyName)
    LocalStorageService.remove(LocalStorageService.presentationTemplates)
    LocalStorageService.remove(LocalStorageService.zones)
    LocalStorageService.remove(LocalStorageService.managers)
    LocalStorageService.remove(LocalStorageService.segments)
    LocalStorageService.remove(LocalStorageService.userData)
    LocalStorageService.remove(LocalStorageService.managers)
    LocalStorageService.remove(LocalStorageService.emailTemplates)
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getTokenAt() {
    return localStorage.getItem(this.jwtConfig.storageTokenAt)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    this.setTokenAt()
  }

  setTokenAt() {
    localStorage.setItem(this.jwtConfig.storageTokenAt, (new Date()).toISOString())
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
