export default {
  // Endpoints
  domain: 'http://127.0.0.1:8081',
  api: process.env.VUE_APP_API,
  oldCms: process.env.VUE_APP_OLD_CMS,
  loginEndpoint: `${process.env.VUE_APP_API}/autenticar`,
  refreshEndpoint: `${process.env.VUE_APP_API}/refresh`,
  registerEndpoint: '/jwt/register',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageTokenAt: 'accessTokenAt',
  storageRefreshTokenKeyName: 'refreshToken',
}
