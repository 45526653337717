import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    toastSuccess(title, text) {
      this.showToast('success', title, text)
    },

    toastDanger(title, text) {
      this.showToast('danger', title, text)
    },

    toastInfo(title, text) {
      this.showToast('info', title, text)
    },

    showToast(type, title, text) {
      const icons = {
        success: 'EditIcon',
        danger: 'BellIcon',
        info: 'InfoIcon',
      }
      const icon = icons[type]

      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant: type,
        },
      })
    },
  },
}
