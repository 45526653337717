<template xmlns:b-row="http://www.w3.org/1999/html">
  <b-card>
    <div
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner />
      <p>Carregando...</p>
    </div>

    <b-form v-if="!isLoading">
      <validation-observer ref="rulesRegistration">
        <h4>Configurações</h4>

        <div
          v-if="!salesManager && ['sales', 'root', 'management_processes'].includes(userData.permission_type)"
          class="mb-2"
        >
          <b-button
            v-b-modal.modal-log
            size="sm"
          >
            Ver log de EV
          </b-button>
        </div>

        <h3>Agendamento de Tour</h3>

        <div
          v-if="results.success_manager_id || userData.permission_type === 'success'"
          class="mb-2"
        >
          <b-form-group
            label="Link para agendar Apresentação do GC"
            label-for="link_to_schedule"
          >
            <b-input-group>
              <b-form-input
                id="link_to_schedule"
                :value="results.link_to_schedule"
                readonly
              />

              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  title="Copiar link"
                  @click="sendToClipboard(results.link_to_schedule)"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  title="Enviar e-mail com link de agendamento"
                  @click="selectEmailToSendLink('presentation')"
                >
                  <b-spinner
                    v-if="isSending"
                    small
                  />
                  <feather-icon
                    v-else
                    icon="SendIcon"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Link para agendar Tour da Plataforma"
            label-for="link_to_schedule_tour"
          >
            <b-input-group>
              <b-form-input
                id="link_to_schedule_tour"
                :value="results.link_to_schedule_tour"
                readonly
              />

              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  title="Copiar link"
                  @click="sendToClipboard(results.link_to_schedule_tour)"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  title="Enviar e-mail com link de agendamento"
                  @click="selectEmailToSendLink('tour')"
                >
                  <b-spinner
                    v-if="isSending"
                    small
                  />
                  <feather-icon
                    v-else
                    icon="SendIcon"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div
          v-else
          class="mb-2"
        >
          <b-button
            @click="$emit('sort-success-manager')"
          >
            Atribuir Consultor Canal VIP
          </b-button>
        </div>

        <b-list-group
          horizontal
          class="mb-1"
        >
          <b-list-group-item v-if="salesManager">
            <h5 class="mt-0 mb-1 d-flex justify-content-between">
              Executivo de vendas

              <b-dropdown
                v-if="['sales', 'root', 'management_processes'].includes(userData.permission_type)"
                no-caret
                variant="link"
                text="..."
                right
                toggle-class="ml-auto p-0"
                :disabled="isSubmiting"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click.stop="showManagerRemovalModal('sales')">
                  Remover consultor
                </b-dropdown-item>
              </b-dropdown>
            </h5>
            <b-media>
              <template #aside>
                <b-avatar
                  size="40px"
                  :src="salesManager.avatar ? salesManager.avatar.sm : ''"
                  variant="light-success"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>
              </template>
              <b>
                {{ salesManager.name }}
              </b>
              <div v-if="results.account_manager_at">
                De: {{ formatDateTimeDbToView(results.account_manager_at) }}
              </div>
              <div v-if="results.account_manager_until">
                Propriedade Até: {{ formatDateTimeDbToView(results.account_manager_property_until) }}
              </div>
              <div v-if="results.account_manager_until">
                Prioridade Até: {{ formatDateTimeDbToView(results.account_manager_priority_until) }}
              </div>
              <div v-if="['sales', 'root', 'management_processes'].includes(userData.permission_type)">
                <b-button
                  v-b-modal.modal-log
                  size="sm"
                >
                  Ver log de EV
                </b-button>
              </div>
            </b-media>
          </b-list-group-item>
          <b-list-group-item v-if="successManager && ['root', 'management_processes'].includes(userData.permission_type)">
            <h5 class="mt-0 mb-1 d-flex d-flex justify-content-between">
              Gerente de conta

              <b-dropdown
                v-if="['sales', 'root', 'management_processes'].includes(userData.permission_type)"
                no-caret
                variant="link"
                text="..."
                right
                toggle-class="ml-auto p-0"
                :disabled="isSubmiting"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  @click.stop="showManagerRemovalModal('success')"
                >
                  Remover consultor
                </b-dropdown-item>
              </b-dropdown>
            </h5>
            <b-media>
              <template #aside>
                <b-avatar
                  size="40px"
                  :src="successManager.avatar ? successManager.avatar.sm : ''"
                  variant="light-success"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>
              </template>
              <b>
                {{ successManager.name }}
              </b>
              <div v-if="results.success_manager_at">
                De: {{ formatDateTimeDbToView(results.success_manager_at) }}
              </div>
              <div v-if="results.success_manager_until">
                Até: {{ formatDateTimeDbToView(results.success_manager_until) }}
              </div>
            </b-media>
          </b-list-group-item>
        </b-list-group>

        <b-modal
          id="modal-manager-removal"
          ref="modal-manager-removal"
          content-class="shadow"
          title="Remover consultor"
          no-fade
          ok-title="Remover"
          @ok="removeManager(managerRemovalType)"
        >
          <b-card-text>
            Você tem certeza que deseja remover o
            <span v-if="managerRemovalType === 'sales'">
              executivo de vendas
            </span>
            <span v-else-if="managerRemovalType === 'success'">
              gerente de contas
            </span>
            deste usuário?
          </b-card-text>
        </b-modal>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label=""
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.receive_alert_of_new_opportunities"
                  :disabled="isSubmiting"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Receber alerta de novas oportunidades
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label=""
              label-for="registration_delay_emails"
            >
              <validation-provider #default="{ errors }">
                <b-form-select
                  id="registration_delay_emails"
                  v-model="results.delay_emails"
                  :options="delayEmails"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label=""
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.receive_alert_on_whatsapp"
                  :disabled="isSubmiting"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Receber alerta de nova conversa no Whats App
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label=""
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.receive_alert_of_new_messages"
                  :disabled="isSubmiting"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Receber alerta de nova conversa/mensagem no e-mail
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label=""
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.receive_automatic_search"
                  :disabled="isSubmiting"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Receber busca automática
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label=""
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.show_phones"
                  :disabled="isSubmiting"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Mostrar telefones
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="userData.permission_type === 'root'"
            cols="12"
          >
            <b-form-group
              label=""
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.indication_reset"
                  :disabled="isSubmiting"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                >
                  Recolocar na fila de indicação
                  <small>(Último envio
                    {{
                      results.indication_sent | date('DD/MM/Y H:m:s')
                    }})</small>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Mensagem Padrão"
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-textarea
                  v-model="results.default_message_to_connection"
                  :disabled="isSubmiting"
                  name="check-button"
                  rows="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <h4 class="mt-1">
              Bot de Resposta
            </h4>
          </b-col>

          <b-col cols="12">
            <div class="text-right">
              <b-button
                variant="secondary"
                @click="randomDefaultResponseToConnection"
              >
                Modelo aleatório
              </b-button>
            </div>
            <b-form-group
              label="Mensagem automática"
              label-for="registration_info_type"
            >
              <validation-provider #default="{ errors }">
                <b-form-textarea
                  v-model="results.default_response_to_connection"
                  :disabled="isSubmiting"
                  name="check-button"
                  rows="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.default_response_only_main_folder"
                  :disabled="isSubmiting"
                  switch
                  inline
                >
                  Responder automaticamente somente as mensagens da Pasta
                  Principal
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.default_response_hide_email"
                  :disabled="isSubmiting"
                  switch
                  inline
                >
                  Ocultar <strong>E-mail</strong> na Resposta Automática
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.default_response_hide_cellphone"
                  :disabled="isSubmiting"
                  switch
                  inline
                >
                  Ocultar <strong>Celular/WhatsApp</strong> na Resposta
                  Automática
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.default_response_hide_phone"
                  :disabled="isSubmiting"
                  name="check-button"
                  switch
                  inline
                >
                  Ocultar <strong>Telefone</strong> na Resposta Automática
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }">
                <b-form-checkbox
                  v-model="results.default_response_delayed"
                  :disabled="isSubmiting"
                  name="check-button"
                  switch
                  inline
                >
                  Ativar espera de 48 horas (neste caso, a Resposta Automática
                  não será enviada imediatamente. Será enviada apenas após 48
                  horas caso você não responda a mensagem)
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              align="right"
              variant="primary"
              type="submit"
              :disabled="isSubmiting || isLoading"
              @click.prevent="updateSettings()"
            >
              Salvar
              <b-spinner
                v-if="isSubmiting"
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

    <b-modal
      v-if="!isLoading"
      id="modal-log"
      ref="modal-log"
      title="Log de vendas"
      footer-class="justify-content-center"
      ok-only
      ok-title="Fechar"
      ok-variant="danger"
    >
      <div
        v-for="log in results.sales_log"
        :key="log.manager_id"
      >
        <span
          class="font-weight-bold d-inline-block"
          style="width: 140px"
        >Consultor: </span>
        <span>{{ log.manager_name }}</span>
        <br />

        <span
          class="font-weight-bold d-inline-block"
          style="width: 140px"
        >Último contato: </span>
        <span>{{ log.last_contacted | date }}</span>
        <br />

        <span
          class="font-weight-bold d-inline-block"
          style="width: 140px"
        >E-mail enviado: </span>
        <span>{{ log.last_email | date }}</span>
        <br />

        <span
          class="font-weight-bold d-inline-block"
          style="width: 140px"
        >Anotação: </span>
        <span>{{ log.last_annotation | date }}</span>
        <br />
        <br />
      </div>

    </b-modal>

  </b-card>
</template>

<script>
import Vue from 'vue'
import UserService from '@/services/userService'
import SchedulerService from '@/services/schedulerService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  VBModal,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
  BMedia,
  BModal,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { formatDateTimeDbToView } from '@/utils/helpers'
import { delayEmails } from '@/utils/options'
import sendToClipboard from '@/mixins/sendToClipboard'
import requestErrorMessage from '@/mixins/requestErrorMessage'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BMedia,
    BModal,
    BRow,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-modal': VBModal,
  },

  mixins: [sendToClipboard, requestErrorMessage],

  props: {
    userId: {
      type: Number,
      default: null,
    },
    userEditing: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      formatDateTimeDbToView,
      delayEmails,
      isSubmiting: false,
      isSending: false,
      userData: null,
      isLoading: true,
      userService: null,
      initExecuted: false,
      settings: '',
      results: {},
      managers: [],
      managerRemovalType: '',
      selectedEmail: '',
    }
  },

  computed: {
    emailsFromUser() {
      return [
        ...[this.userEditing.email ?? ''],
        ...this.userEditing.emails ?? [],
      ]
    },

    salesManager() {
      if (!this.results.account_manager_id) return null

      return this.managers.find(f => f.id === this.results.account_manager_id)
    },
    successManager() {
      if (!this.results.success_manager_id) return null

      return this.managers.find(f => f.id === this.results.success_manager_id)
    },
  },

  async created() {
    this.userService = new UserService()
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)
  },

  methods: {
    selectEmailToSendLink(type) {
      this.$swal({
        title: 'Convite',
        text: 'Enviar convite de agendamento para o usuário?',
        icon: 'question',
        confirmButtonText: 'Sim, enviar convite',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isSending = true
          SchedulerService
            .postSendLinkToSchedule(this.userData.id, this.userId, type)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'E-mail enviado',
                  text: '',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Ops - Existem erros de validação',
                text: this.getResponseErrorMessage(error.response.data),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).finally(() => {
              this.isSending = false
            })
        }
      })
    },

    userHash(userEditing) {
      return SchedulerService.getHash(userEditing.created_at)
    },

    async init() {
      if (!this.initExecuted) {
        await this.userService.getAndStoreManagers().then(managers => {
          this.managers = managers
        })
        await this.getSettings(this.userId)
        this.initExecuted = true
      }
    },

    async reset() {
      if (!this.initExecuted) {
        await this.init()
      } else {
        await this.getSettings(this.userId)
      }
    },

    openTransaction(row) {
      this.$emit('open-transaction', row.item.payment_transaction_id)
    },

    getSettings(userId) {
      return this.userService.listSettings(userId).then(response => {
        const {
          default_message_to_connection,
          receive_alert_of_new_opportunities,
          receive_alert_on_whatsapp,
          receive_automatic_search,
          receive_alert_of_new_messages,
          show_phones,
          indication_sent,
          account_manager_id,
          account_manager_at,
          account_manager_until,
          account_manager_priority_until,
          account_manager_property_until,
          success_manager_id,
          success_manager_at,
          success_manager_until,
          delay_emails,
          default_response_to_connection,
          default_response_only_main_folder,
          default_response_hide_email,
          default_response_hide_cellphone,
          default_response_hide_phone,
          default_response_delayed,
          link_to_schedule,
          link_to_schedule_tour,
          sales_log,
        } = response.data.data.settings

        this.results = {
          default_message_to_connection,
          receive_alert_of_new_opportunities,
          receive_alert_on_whatsapp,
          receive_automatic_search,
          receive_alert_of_new_messages,
          show_phones,
          indication_sent,
          indication_reset: false,
          account_manager_id,
          account_manager_at,
          account_manager_until,
          account_manager_priority_until,
          account_manager_property_until,
          success_manager_id,
          success_manager_at,
          success_manager_until,
          delay_emails,
          default_response_to_connection,
          default_response_only_main_folder,
          default_response_hide_email,
          default_response_hide_cellphone,
          default_response_hide_phone,
          default_response_delayed,
          link_to_schedule,
          link_to_schedule_tour,
          sales_log,
        }
        this.isLoading = false
      })
    },

    updateSettings() {
      this.isSubmiting = true

      const {
        default_message_to_connection,
        receive_alert_of_new_opportunities,
        receive_alert_on_whatsapp,
        receive_automatic_search,
        receive_alert_of_new_messages,
        show_phones,
        indication_reset,
        delay_emails,
        default_response_to_connection,
        default_response_only_main_folder,
        default_response_hide_email,
        default_response_hide_cellphone,
        default_response_hide_phone,
        default_response_delayed,
      } = this.results

      const data = {
        default_message_to_connection,
        receive_alert_of_new_opportunities,
        receive_alert_on_whatsapp,
        receive_alert_of_new_messages,
        receive_automatic_search,
        show_phones,
        indication_reset,
        delay_emails,
        default_response_to_connection,
        default_response_only_main_folder,
        default_response_hide_email,
        default_response_hide_cellphone,
        default_response_hide_phone,
        default_response_delayed,
      }

      this.userService
        .updateSettings(this.userId, data)
        .then(() => {
          this.isSubmiting = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Alteração salva',
              text: '',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Ops - Existem erros de validação',
            text: Object.values(error.response.data.errors),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.isSubmiting = false
        })
    },

    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
      }
    },

    showManagerRemovalModal(type) {
      this.managerRemovalType = type
      this.$refs['modal-manager-removal'].show()
    },

    removeManager(type) {
      this.isSubmiting = true

      UserService.removeManager(this.userId, type)
        .then(() => {
          this.isSubmiting = false

          if (type === 'success') {
            this.results = {
              ...this.results,
              success_manager_id: null,
              success_manager_at: null,
            }
          } else if (type === 'sales') {
            this.results = {
              ...this.results,
              account_manager_id: null,
              account_manager_at: null,
            }
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Consultor Removido',
              text: '',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Não foi possível remover o consultor',
            text: Object.values(error.response.data.errors),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {
          this.isSubmiting = false
        })
    },
    randomDefaultResponseToConnection() {
      const cellphone = Vue.filter('phone')(this.userEditing?.cell_phone)
      const { email } = this.userEditing
      const defaultResponses = [
        `Obrigado pelo seu interesse! Fico à disposição para conversarmos melhor sobre o assunto.

Por favor, entre em contato comigo por telefone/WhatsApp no número ${cellphone} ou por email no endereço ${email}.

Aguardo seu contato.`,
        `Agradeço pelo seu interesse! Seria ótimo podermos conversar mais sobre o assunto.

Por favor, entre em contato comigo por telefone/WhatsApp, no número ${cellphone}, ou por email, no endereço ${email}. Espero ouvir de você em breve.`,
        `Obrigado por demonstrar interesse! Para conversarmos melhor sobre o assunto, peço que entre em contato comigo.

Você pode me ligar ou enviar uma mensagem via telefone/WhatsApp no número ${cellphone}, ou me enviar um email para ${email}.

Estou aguardando seu contato.`,
        `Agradeço pelo interesse demonstrado! Estou disponível para conversarmos mais sobre o assunto.

Por favor, entre em contato comigo por telefone/WhatsApp, no número ${cellphone}, ou por email, no endereço ${email}.

Espero receber sua mensagem em breve.`,
        `Obrigado pelo interesse! Ficarei feliz em conversar mais sobre o assunto.

Por favor, entre em contato comigo por telefone/WhatsApp, no número ${cellphone}, ou por email, no endereço ${email}.

Aguardo seu contato para discutirmos melhor.`,
        `Agradeço pelo interesse manifestado! Para podermos discutir melhor o assunto, peço que entre em contato comigo. Você pode me ligar ou enviar uma mensagem via telefone/WhatsApp no número ${cellphone}, ou me enviar um email para ${email}.

Aguardo seu retorno.`,
        `Obrigado pelo interesse! Será um prazer conversar mais sobre o assunto. Por favor, entre em contato comigo por telefone/WhatsApp, no número ${cellphone}, ou por email, no endereço ${email}.

Aguardo para trocar informações com você.`,
        `Agradeço pelo interesse! Estou à disposição para conversarmos melhor sobre o assunto. Por favor, entre em contato comigo por telefone/WhatsApp, no número ${cellphone}, ou por email, no endereço ${email}.

Aguardo seu contato para darmos continuidade à conversa.`,
        `Obrigado pelo seu interesse! Fico à disposição para conversarmos mais sobre o assunto. Por favor, entre em contato comigo por telefone/WhatsApp, no número ${cellphone}, ou por email, no endereço ${email}.

Aguardo seu retorno para prosseguirmos.`,
        `Agradeço pelo interesse manifestado! Será um prazer conversar mais sobre o assunto.

Por favor, entre em contato comigo por telefone/WhatsApp, no número ${cellphone}, ou por email, no endereço ${email}.

Estou aguardando seu contato para trocarmos informações.`,
        `Obrigado pelo interesse! Por favor, entre em contato comigo por telefone/WhatsApp, ou por email, para conversarmos melhor sobre o assunto.
${cellphone}
${email}

Att,`,
      ]

      const seed = Math.round(Date.now() / 1000)
      const prng = seed % defaultResponses.length

      this.results.default_response_to_connection = defaultResponses[prng]
    },
  },
}
</script>

<style scoped>
h4 {
  margin-bottom: 20px;
}
</style>
